import { DateTime } from 'luxon';
import { useNuxtApp } from 'nuxt/app';

import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import { COOKIE_KEYS, DEFAULT_COUNTRY } from '@/constants/cookie.const';
import {
  DATE_TIME_DOMESTIC,
  DATE_TIME_DOMESTIC_WITHOUT_HOUR,
  DATE_TIME_FORMAT,
  DATE_TIME_INTERNATIONAL,
  DATE_TIME_INTERNATIONAL_WITHOUT_HOUR,
  DATE_WITH_THE_DAY_OF_THE_WEEK,
  MONTH_YEAR_DOMESTIC,
  MONTH_YEAR_INTERNATIONAL,
  ZONE
} from '@/constants/date-time.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import type { OrNull, OrNullish } from '@/types/core.type';

/** get time offset in hour */
export const getTimeOffset = () => {
  return (DateTime.local().offset * 10) / (10 * 60);
};

/**
 * return a Date | null
 * @param string | number | null | undefined | date
 * @returns Date | null
 * Examples: For current date = 2023-08-22 16:40, UTC+7, no input zone
 * 1692696654000                => Tue Aug 22 2023 16:30:54 GMT+0700
 * 1692696654                   => Tue Aug 22 2023 16:30:54 GMT+0700
 * new Date()                   => Tue Aug 22 2023 16:40:00 GMT+0700
 * '2023-08-22'                 => Tue Aug 22 2023 07:00:00 GMT+0700
 * '2023-08-22 08:00'           => Tue Aug 22 2023 15:00:00 GMT+0700
 * '2023/08/22 08:00'           => Tue Aug 22 2023 15:00:00 GMT+0700
 * '2023-08-22T08:00'           => Tue Aug 22 2023 15:00:00 GMT+0700
 * '2023-08-22T08:00:00'        => Tue Aug 22 2023 15:00:00 GMT+0700
 * '2023-08-22T09:40:00.927Z'   => Tue Aug 22 2023 16:40:00 GMT+0700
 * '2023-08-22T09:40:00+07:00'  => Tue Aug 22 2023 09:40:00 GMT+0700
 */

export const parseDate = (
  param: OrNullish<string | number | Date>,
  zone?: string
): OrNull<Date> => {
  if (!param) {
    return null;
  }

  // number: milliseconds or seconds
  if (typeof param === 'number') {
    return param.toString().length > 10
      ? DateTime.fromMillis(param).toJSDate()
      : DateTime.fromSeconds(param).toJSDate();
  }

  // js date object
  if (typeof param === 'object' && param instanceof Date) {
    return param;
  }

  if (typeof param === 'string' && param.trim().length > 0) {
    param = param.trim().replace(/\//g, '-');
    if (param.includes(' ')) {
      // yyyy-MM-dd HH:mm
      return DateTime.fromSQL(param, { zone: ZONE.UTC }).toJSDate();
    }

    // if (param.includes('T') && param.length < 20) {
    if (param.length < 20) {
      return DateTime.fromISO(param, { zone: ZONE.UTC }).toJSDate();
    }

    // ISO https://github.com/moment/luxon/blob/master/docs/parsing.md#iso-8601
    return DateTime.fromISO(param, zone ? { zone } : undefined).toJSDate();
  }

  return null;
};

/**
 * format date form multiple input value format
 * @param string | number | null | undefined | date
 * @returns string | null
 * Param examples:
 * 1692696654000
 * 1692696654
 * new Date()
 * '2023-08-22'
 * '2023-08-22 08:00'
 * '2023/08/22 08:00'
 * '2023-08-22T08:00'
 * '2023-08-22T08:00:00'
 * '2023-08-22T09:40:00.927Z'
 * '2023-08-22T09:40:00+07:00'
 */
export const formatDateTime = (
  value: OrNullish<string | number | Date>,
  format: string = DATE_TIME_FORMAT,
  zone: string = ZONE.LOCAL,
  inputZone?: string
) => {
  const { locale } = useNuxtApp().$i18n as any;
  const date = parseDate(value);

  if (!date) {
    return '-';
  }

  return DateTime.fromJSDate(date, inputZone ? { zone: inputZone } : undefined)
    .setZone(zone)
    .setLocale(locale.value)
    .toFormat(format);
};

export const getDateWithOffset = (timezone: string, date: string | Date): string | null => {
  if (!date) {
    return null;
  }
  return DateTime.fromISO(String(date), { zone: timezone }).toISO();
};

export const getDateTimeRange = (startDt: number, endDt: number): string => {
  const startDate = DateTime.fromMillis(startDt).toFormat(DATE_TIME_FORMAT);
  const endDate = DateTime.fromMillis(endDt).toFormat(DATE_TIME_FORMAT);

  return `${startDate} ~ ${endDate}`;
};

export const getUtcTimezone = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const sign = offset > 0 ? '-' : '+';
  const hours = Math.floor(Math.abs(offset) / 60);
  return `UTC ${sign}${hours}`;
};

export const getFormatDateMonthByLocale = (): string => {
  const nation = useCookieWithDomain(COOKIE_KEYS.NNTO || '').value || DEFAULT_COUNTRY;
  return nation === DEFAULT_COUNTRY ? MONTH_YEAR_DOMESTIC : MONTH_YEAR_INTERNATIONAL;
};

export const getFormatByLocale = (isShowHour: boolean = true): string => {
  const nation = useCookieWithDomain(COOKIE_KEYS.NNTO || '').value || DEFAULT_COUNTRY;
  return nation === DEFAULT_COUNTRY
    ? isShowHour
      ? DATE_TIME_DOMESTIC
      : DATE_TIME_DOMESTIC_WITHOUT_HOUR
    : isShowHour
      ? DATE_TIME_INTERNATIONAL
      : DATE_TIME_INTERNATIONAL_WITHOUT_HOUR;
};

export const getDateTimeByLocale = (date: string | Date | number, isShowHour?: boolean): string => {
  return formatDateTime(date, getFormatByLocale(isShowHour));
};

export const getOrdinalSuffixByLocale = (date: string | Date): string => {
  const { locale } = useNuxtApp().$i18n as any;
  const dt = DateTime.fromISO(String(date));
  const day = dt.day;
  if (locale.value === DEFAULT_LOCALE) {
    return `${day}일`;
  } else {
    return `${day}${getOrdinalSuffix(day)}`;
  }
};

export const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getTheDayOfTheWeekByLocale = (date: string | Date): string => {
  return formatDateTime(date, DATE_WITH_THE_DAY_OF_THE_WEEK);
};

export const getStartEndDates = () => {
  const endDate = new Date();
  const startDate = new Date();
  const dayOfWeek = startDate.getUTCDay();
  const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

  startDate.setUTCDate(startDate.getUTCDate() - diffToMonday);
  startDate.setUTCHours(0, 0, 0, 0);

  return {
    startDatetime: startDate.getTime(),
    endDatetime: endDate.getTime()
  };
};

export const getDateFromXDaysAgo = (dayAgo: number) => {
  // getDateFromXDaysAgo(7) => 7 days ago so days = 6
  const days = dayAgo - 1;
  return DateTime.now().startOf('day').minus({ days }).toUTC().toISODate();
};

export const getFirstDateOfCurrentMonth = () => {
  return DateTime.now().toUTC().startOf('month').toISODate();
};

export const getCurrentDate = () => {
  return DateTime.now().toUTC().toISODate();
};

export const formatToDateTime = (date: Date | number | DateTime): DateTime => {
  const isDateTime = date instanceof DateTime;
  if (isDateTime) {
    return date;
  }
  return DateTime.fromJSDate(new Date(date));
};

export const formatUnknownSrcToMillis = (date: Date | number | DateTime): number => {
  if (!date) {
    return 0;
  }
  return formatToDateTime(date).toMillis();
};
